import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

// Connects to data-controller="consumption-point"
export default class extends Controller {
  static targets = [
    "consumptionPoint",
    "totalValue",
    "tableHeadTotalValue",
    "qtyInput",
    "form",
    "maxValue",
    "transferringAmountInput",
    "disabledLotAmountInput",
    "qtyInputError"
  ];

  connect() {
    if (this.hasTableHeadTotalValue == true) {
      setTimeout(() => {
        this.calculateTotal();
        this.qtyInputTargets.forEach((input) => {
          input.addEventListener("change", (e) => this.calculateTotal(e));
        });
      }, 0);
    }

    if (this.hasDisabledLotAmountInputTarget) {
      this.disabledLotAmountInputTarget.dispatchEvent(new Event('change'));
    }
  }

  dispatchCloseModalEvent() {
    const event = new CustomEvent('closeModal', { bubbles: true, cancelable: true });
    document.dispatchEvent(event);
  }

  calculateTotal(e) {
    let totalValue = 0;
    this.qtyInputTargets.forEach((input) => {
      totalValue += Number(input.value) || 0;
    });
    if (e) {
      const unitName = e.target.dataset.unitName;
      const unitMultipler = Number(unitName.match(/\d+/g));

      this.tableHeadTotalValueTarget.textContent = `${totalValue} x ${unitName} = ${
        totalValue * unitMultipler
      }`;
    }
  }

  saveTotalQty(e) {
    let total = 0;
    const maxValue = Number(this.maxValueTarget.dataset.maxValue);
    let allInputsAreIntegers = true;

    this.qtyInputTargets.forEach((inputTarget) => {
      const numericValue = Number(inputTarget.value) || 0;
      if (inputTarget.value !== "" && !Number.isInteger(numericValue)) {
        allInputsAreIntegers = false;
      }
      total += numericValue;

      const stockCardId = inputTarget.dataset.stockCardId;
      const stockCardErrorElement = document.getElementById(`stock-card-error-${stockCardId}`);
      const stockCardErrorExists = stockCardErrorElement && stockCardErrorElement.textContent.trim() !== '';

      if (total > maxValue && !stockCardErrorExists) {
        this.qtyInputErrorTargets.forEach((errorTarget) => {
          errorTarget.classList.remove('hidden');
        });
        return;
      }

      if (!stockCardErrorExists) {
        this.qtyInputErrorTargets.forEach((errorTarget) => {
          errorTarget.classList.add('hidden');
        });
      }
    });

    if (!allInputsAreIntegers) {
      e.preventDefault();
      $notification({ text: e.target.dataset.nonIntegerAlertMessage, variant: 'error' });
      return;
    }

    this.totalValueTarget.value = this.numberWithCommas(total);
    this.updateTransferringInput(total);
    this.dispatchCloseModalEvent();
  }

  updateTransferringInputForLotDisabled() {
    /* for stock card's stock tracking disabled ware details */
    this.updateTransferringInput(this.disabledLotAmountInputTarget.value);
  }

  updateTransferringInput(amount) {
    if (this.hasTransferringAmountInputTarget) {
      this.transferringAmountInputTarget.value = amount === "" ? "" : Number(amount);
    }
  }

  numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
}
