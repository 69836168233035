import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form"
export default class extends Controller {

  connect() {
    this.enableElements();
    this.addTurboEventListeners();
  }

  addTurboEventListeners() {
    this.element.addEventListener("turbo:submit-end", this.enableElements.bind(this));
  }

  blockMultipleSubmission() {
    this.disableElements();
  }
  
  disableElements() {
    const elements = this.element.querySelectorAll('a, button, input[type=button], input[type=submit]');
    elements.forEach(element => {
      element.setAttribute('disabled', 'disabled');
    });
  }

  enableElements() {
    const elements = this.element.querySelectorAll('a, button, input[type=button], input[type=submit]');
    elements.forEach(element => {
      element.removeAttribute('disabled');
    });
  }
}