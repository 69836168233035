import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["testGroupList", "testGroup"];

  connect() {
    if (typeof this.testGroupTarget.tomselect === "undefined") {
      // Wait until select-controller.js loaded
      setTimeout(() => {
        this.connect();
      });
    } else {
      this.fetchTestGroups();
    }
  }

  fetchTestGroups() {
    const testGroupIds = this.testGroupTarget.tomselect.getValue();
    const formattedTestGroupIds = testGroupIds.join(",");

    const consumptionPointId = this.element.dataset.consumptionPointId;

    const url = `/consumption_point_test_groups?test_group_ids=${formattedTestGroupIds}&consumption_point_id=${consumptionPointId}`;

    fetch(url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      });
  }
}
