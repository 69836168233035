import { Controller } from "@hotwired/stimulus"
import Tabulator from '../extensions/TabulatorExtended';

// Connects to data-controller="tabulator"
export default class extends Controller {
  static values = {
    url: String,
    mode: String,
    columns: Array,
    pageSize: Number
  }

  connect() {
    this.tabulator = new Tabulator(this.element, {
      ajaxURL: this.urlValue,
      ajaxConfig: "GET",
      layout: "fitColumns",
      pagination: this.modeValue,
      paginationSize: this.pageSizeValue,
      sortMode: this.modeValue,
      columns: this.columnsValue,
      locale: true,
      dataLoaderLoading: "<div class='tabulator-spinner'></div>"
    });
  }

  disconnect() {
    if (this.tabulator) {
      this.tabulator.destroy();
      this.tabulator = null;
    }
  }
}
