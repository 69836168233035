import { Controller } from "@hotwired/stimulus";
import { focusNestedFormTargetElement } from "../helpers/focus_nested_form_target_element";

// Connects to data-controller="warehouse-demand"
export default class extends Controller {
  static targets = [
    "totalValue",
    "tableHeadTotalValue",
    "qtyInput",
    "transferModal",
    "transferDemandQuantity",
    "transferError",
    "transferCoveredAmount",
    "maxValue",
    "transferAmountInput",
    "transferringAmountInput",
    "disabledLotAmountInput",
    "qtyInputError",
    "wareDetailDemandQuantity"
  ];

  connect() {
    document.addEventListener('focus_wareDetailDemandQuantityTargets',
      (event) => focusNestedFormTargetElement(event.detail, this.wareDetailDemandQuantityTargets));

    if (this.hasTableHeadTotalValueTarget) {
      setTimeout(() => {
        this.qtyInputTargets.forEach((input) => {
          input.addEventListener("change", (e) => this.saveTotalQty(e));
          input.dispatchEvent(new Event('change'));
        });
      }, 0);
    }

    if (this.hasDisabledLotAmountInputTarget) {
      this.disabledLotAmountInputTarget.dispatchEvent(new Event('change'));
    }
  }

  dispatchCloseModalEvent() {
    const event = new CustomEvent('closeModal', { bubbles: true, cancelable: true });
    document.dispatchEvent(event);
  }

  calculateTotal(e) {
    let totalValue = 0;
    this.qtyInputTargets.forEach((input) => {
      totalValue += Number(input.value) || 0;
    });
    if (e) {
      const unitName = e.target.dataset.unitName;
      const unitMultipler = Number(unitName.match(/\d+/g));

      this.tableHeadTotalValueTarget.textContent = `${totalValue} x ${unitName} = ${
        totalValue * unitMultipler
      }`;
    }
  }

  saveTotalQty(e) {
    let total = 0;
    const maxValue = Number(this.maxValueTarget.dataset.maxValue);
    let allInputsAreIntegers = true;

    this.qtyInputTargets.forEach((inputTarget) => {
      const numericValue = Number(inputTarget.value) || 0;
      if (inputTarget.value !== "" && !Number.isInteger(numericValue)) {
        allInputsAreIntegers = false;
      }
      total += numericValue;
      const stockCardId = inputTarget.dataset.stockCardId;
      const stockCardErrorElement = document.getElementById(`stock-card-error-${stockCardId}`);
      const stockCardErrorExists = stockCardErrorElement && stockCardErrorElement.textContent.trim() !== '';

      if (total > maxValue && !stockCardErrorExists) {
        this.qtyInputErrorTargets.forEach((errorTarget) => {
          errorTarget.classList.remove('hidden');
        });
        return;
      }

      if (!stockCardErrorExists) {
        this.qtyInputErrorTargets.forEach((errorTarget) => {
          errorTarget.classList.add('hidden');
        });
      }
    });

    if (!allInputsAreIntegers) {
      e.preventDefault();
      $notification({ text: e.target.dataset.nonIntegerAlertMessage, variant: 'error' });
      return;
    }

    this.totalValueTarget.value = this.numberWithCommas(total);
    this.updateTransferringInput(total);
    this.dispatchCloseModalEvent();
  }

  updateTransferringInputForLotDisabled() {
    /* for stock card's stock tracking disabled ware details */
    this.updateTransferringInput(this.disabledLotAmountInputTarget.value);
  }

  updateTransferringInput(amount) {
    if (this.hasTransferringAmountInputTarget) {
      this.transferringAmountInputTarget.value = amount === "" ? "" : Number(amount);
    }
  }

  updateWareTable() {
    const transferWareDataAttribute = document.querySelectorAll(
      'input[type="checkbox"][name*="[transfer_ware]"]:checked',
    );

    const transferWareObjects = Array.from(transferWareDataAttribute).map(
      (checkbox) => {
        let nameAttribute = checkbox.getAttribute("name");
        let coveredAmountName = nameAttribute.replace(
          "transfer_ware",
          "covered_amount",
        );
        let coveredAmountField = document.getElementsByName(coveredAmountName);
        let transferWareObject = {
          stockName: checkbox.getAttribute("data-stock-name"),
          id: checkbox.getAttribute("data-ware-id"),
          coveredAmount: coveredAmountField[0].valueAsNumber,
        };
        return transferWareObject;
      },
    );

    const hiddenWareInput = document.getElementById("hidden-field-table");
    const wareTable = document.getElementById("ware-table");

    hiddenWareInput.innerHTML = "";
    wareTable.innerHTML = "";

    transferWareObjects.forEach((transferWareObject) => {
      const hiddenObject = Object.assign(document.createElement("input"), {
        type: "hidden",
        name: "warehouse_demand[selected_ware_detail_object][]",
        value: `{"stock_name":"${transferWareObject.stockName}", "id":"${transferWareObject.id}", "covered_amount":"${transferWareObject.coveredAmount}"}`,
      });
      hiddenWareInput.appendChild(hiddenObject);
      var tableDescriptionElement = Object.assign(document.createElement("td"));
      var numberField = Object.assign(document.createElement("input"), {
        type: "number",
        value: transferWareObject.coveredAmount,
        disabled: true,
      });
      var contentSpan = Object.assign(document.createElement("span"), {
        textContent: transferWareObject.stockName,
      });
      var tableRowElement = document.createElement("tr");
      tableDescriptionElement.classList.add("flex", "justify-between");
      contentSpan.classList.add("mr-4", "px-3", "py-2");
      numberField.classList.add(
        "bg-slate-100",
        "rounded-lg",
        "border",
        "border-slate-300",
        "bg-transparent",
        "px-3",
        "py-2",
      );
      tableDescriptionElement.appendChild(contentSpan);
      tableDescriptionElement.appendChild(numberField);
      tableRowElement.appendChild(tableDescriptionElement);
      wareTable.appendChild(tableRowElement);
    });
  }

  isQuantityCovered(demandQuantityField, coveredAmountField) {
    return parseInt(demandQuantityField.textContent) <= coveredAmountField.valueAsNumber;
  }

  openWarehouseDemandTransferModal() {
    const { transferDemandQuantityTargets: demandQuantityFields, transferCoveredAmountTargets: coveredAmountFields,
            transferErrorTargets: errorFields } = this;

    const allQuantitiesCovered = demandQuantityFields.every((field, i) => this.isQuantityCovered(field, coveredAmountFields[i]));

    errorFields.forEach((field, i) => field.classList.toggle('hidden', allQuantitiesCovered));

    if (allQuantitiesCovered) {
      this.transferModalTarget.classList.add("show");
    }
  }

  numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
}
