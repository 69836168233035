import {Controller} from "@hotwired/stimulus";
import {buildHeaders, buildRequest, buildUrl} from "../helpers/build_request";
import {sendTurboStreamRequest} from "../helpers/turbo_stream_helper";

// Connects to data-controller="consumption-point-util"
// TODO: :This controller name has to change or merged with consumption_point_stock_controller.
//  Let decide this after consumption/warehouse code refactor.
export default class extends Controller {
  static targets = ["consumptionPoint", "deviceCard", "stockCard", "lotAndExpire", "unit", "qrScanner", "lotWrapper"];

  static values = {
    stockTurboRequestUrl: String
  }

  connect() {
    // TODO: : Not call recursively
    if (this.hasConsumptionPointTarget) {
      if (typeof this.consumptionPointTarget.tomselect === 'undefined') {
        // Wait until select-controller.js loaded
        setTimeout(() => {
          this.connect();
        }, 100);
      }
      if (this.consumptionPointTarget.tomselect) {
        this.initialStates();
      }
    }
  }

  initialStates() {
    this.disable(this.deviceCardTarget);
    this.disable(this.stockCardTarget);
    this.disable(this.lotAndExpireTarget);
    // Page redirected from qr scanned for generated device qr
    // So warehouseId is in object
    const warehouseId = this.consumptionPointTarget.dataset.warehouseId;
    if (warehouseId) {
      this.consumptionPointTarget.tomselect.setValue(warehouseId); // This trigger select turbo request.
    }
  }

  async displayUsage() {
    let selectedConsumptionPointId = this.consumptionPointTarget.tomselect.getValue();

    if (selectedConsumptionPointId === "") {
      this.disable(this.deviceCardTarget);
      this.disable(this.stockCardTarget);
      this.disable(this.lotAndExpireTarget);
      this.qrScannerTarget.classList.add("hidden");
    } else {
      this.qrScannerTarget.classList.remove("hidden");
      this.enable(this.deviceCardTarget);
      this.enable(this.stockCardTarget);
      this.lotAndExpireTarget.tomselect.clear(true);
      this.lotAndExpireTarget.tomselect.clearOptions();
      this.unitTarget.tomselect.clear(true);
      this.unitTarget.tomselect.clearOptions();
      this.disable(this.lotAndExpireTarget);
    }
  }

  stockTurboStreamRequest() {
    const consumptionPointId = this.consumptionPointTarget.tomselect.getValue();
    const stockCardId = this.stockCardTarget.tomselect.getValue();
    if (stockCardId !== "" && consumptionPointId !== "") {
      const params = {id: stockCardId, consumption_point_id: consumptionPointId}
      const url = buildUrl(this.stockTurboRequestUrlValue, params);
      const request = buildRequest(url, buildHeaders());
      sendTurboStreamRequest(request);
    }
  }

  async displayStock() {
    let selectedStockCardId = this.stockCardTarget.tomselect.getValue();
    if (selectedStockCardId !== "") {
      this.enable(this.lotAndExpireTarget);
      this.enable(this.unitTarget);
    }
  }

  async disableNotTracked() {
    const lotAndExpire = this.lotAndExpireTarget.tomselect.getValue();
    if (lotAndExpire === ' - ') {
      this.lotWrapperTarget.classList.add("pointer-events-none");
      const tsControl = this.lotWrapperTarget.querySelector('.ts-wrapper .ts-control');
      if (tsControl) {
        tsControl.style.backgroundColor = "#F4F4F5";
      }
    } else {
      this.lotWrapperTarget.classList.remove("pointer-events-none");
      const tsControl = this.lotWrapperTarget.querySelector('.ts-wrapper .ts-control');
      if (tsControl) {
        tsControl.style.backgroundColor = "";
      }
      this.enable(this.lotAndExpireTarget);
    }
  }

  enable(input) {
    input.disabled = false;
    input.tomselect.unlock();
    input.tomselect.enable();
  }

  disable(input) {
    input.disabled = true;
    input.tomselect.disable();
  }
}
