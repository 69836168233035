import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";

export default class extends Controller {
  static values = {
    card: String,
    chartType: String,
    consumptionPointIds: Array
  };

  connect() {
    super.connect();
    switch (this.chartTypeValue) {
      case "lastYear":
        this.getLastOneYearChartOptions();
        break;
      case "stockDashboard":
        this.getStockDashboardChartOptions();
        break;
    }
  }

  getStockDashboardChartOptions() {
    fetch(`/stock_card_chart_options?id=${this.cardValue}`)
      .then((response) => response.json())
      .then((data) => {
        this.initializeStockDashboardChart(data);
      });
  }

  async getLastOneYearChartOptions() {
    const { cardValue, consumptionPointIdsValue } = this
    const params = new URLSearchParams({
      id: cardValue,
      consumption_point_ids: JSON.stringify(consumptionPointIdsValue)
    })

    const response = await fetch(`/one_year_consumption_chart_options?${params.toString()}`)
    const data = await response.json()
    this.initializeLastOneYearChart(data)
  }

  initializeStockDashboardChart(data) {
    const params = {
      series: data["options"],
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 0,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        type: "category",
        categories: data["months"],
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    };

    const chart = document.querySelector("#stockCardChart");

    setTimeout(() => {
      chart._chart = new ApexCharts(chart, params);
      chart._chart.render();
    });
  }

  initializeLastOneYearChart(data) {
    const months = data.data.map((item) => item.month);
    const consumption = data.data.map((item) => item.consumption);
    const chartTitle = data.chart_title;

    const options = {
      series: [
        {
          name: chartTitle,
          data: consumption,
        },
      ],
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: months,
      },
    };

    const chart = new ApexCharts(document.querySelector("#oneYearConsumptionChart"), options);
    chart.render();
  }
}
