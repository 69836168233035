import { Controller } from "@hotwired/stimulus";
import { downloadPdf } from "../helpers/download_pdf";

export default class extends Controller {
  static targets = ["pdfButton"];

  pdfButtonTargetConnected() {
    this.pdfButtonTarget.addEventListener('click',(event) => downloadPdf(event));
  }
}
