import {Controller} from "@hotwired/stimulus"
import {buildUrl} from "../helpers/build_request";

export default class extends Controller {
  static targets = ["form", "input", "fullText", "query"];

  connect() {
    if (this.hasQueryTarget) {
      this.syncFullTextField()
    }
  }

  submit() {
    this.addTabFilterToParams();
    this.notify();
  }

  notify() {
    document.addEventListener("turbo:frame-render", () => {
      $notification({ text: this.formTarget.dataset.notify, variant: 'success' });
    }, { once: true });
  }

  syncFullTextField(event) {
    this.fullTextTarget.value = this.queryTarget.value;
  }

  checkToSubmit(event) {
    // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
    if (event.key === "Enter") {
      event.preventDefault();
      this.addTabFilterToParams();
      this.submitWithTurbo();
    }
  }

  submitWithTurbo() {
    const filterForm = this.formTarget;
    let filterFormParams = new URLSearchParams(new FormData(filterForm));
    const url = buildUrl(this.queryTarget.dataset.turboUrl, {});
    // Too keep hidden filter close, send a param named {keep_open: false};
    // The goal is to update search results using Turbo when the user presses Enter,
    // refresh the filters, and keep the filter hidden.
    filterFormParams.append('keep_open', 'false');
    url.search = filterFormParams;
    Turbo.visit(url, {
        action: "replace",
        frame: this.queryTarget.dataset.frameId
      }
    )
    this.notify();
  }

  // TODO: : Replace empty view component with turbo
  clearInputs(event) {
    if (this.hasInputTarget) {
      this.inputTargets.forEach(element => {
        switch (element.type) {
          case 'number':
            element.value = '';
            break;
          case 'text':
            element.value = '';
            break;
          case 'select-one':
            element.value = 'all';
            break;
          case 'hidden': /* flatpickr generated field */
            element.value = '';
            element._flatpickr.clear();
            break;
          case 'checkbox':
            element.checked = false;
            break;
          case 'select-multiple':
            for (let i = 0; i < element.options.length; i++) {
              element.options[i].selected = false;
              element.tomselect.clear();
            }
            break;
        }
      });
    }
    this.clearFullTextAndQueryTargets();
  }

  clearFullTextAndQueryTargets() {
    if (this.hasFullTextTarget && this.hasQueryTarget && this.queryTarget.value !== '') {
      this.fullTextTarget.value = "";
      this.queryTarget.value = "";
    }
  }

  addTabFilterToParams() {
    const tabFilterName = this.element.dataset.tabFilterName
    if (tabFilterName !== '') {
      const urlParams = new URLSearchParams(window.location.search);
      const tabFilterParam = urlParams.get(tabFilterName);
      const pageLengthParams = urlParams.get("page_length");
      const idParams = urlParams.get("id");
      const labIdParams = urlParams.get("lab_id");


      if (tabFilterParam) {
        this.addHiddenInputToForm(tabFilterParam, tabFilterName);
      }

      if (pageLengthParams) {
        this.addHiddenInputToForm(pageLengthParams, "page_length");
      }

      if (idParams) {
        this.addHiddenInputToForm(idParams, "id");
      }
      if (labIdParams) {
        this.addHiddenInputToForm(labIdParams, "lab_id");
      }
    }
  }

  addHiddenInputToForm(filterParam, name) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = name;
    input.value = filterParam;
    this.formTarget.appendChild(input);
  }
}
