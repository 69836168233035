import { TabulatorFull as Tabulator } from 'tabulator-tables';

initializeTabulatorExtensions();

function initializeTabulatorExtensions() {
  extendLocalization();
  extendFormatters();
  extendSorters();
}

function extendLocalization() {
  Tabulator.extendModule("localize", "langs", {
    "tr": {
      "pagination": {
        "prev": "Önceki",
        "prev_title": "Önceki Sayfa",
        "next": "Sonraki",
        "next_title": "Sonraki Sayfa"
      }
    }
  });
}

function extendFormatters() {
  Tabulator.extendModule("format", "formatters", {
    numberWithHtmlFormatter: (cell, formatterParams, onRendered) => {
      const value = cell.getValue();
      return value && typeof value === 'object' && value.display ? value.display : '-';
    }
  });
}

function extendSorters() {
  Tabulator.extendModule("sort", "sorters", {
    numberWithHtmlSorter: (a, b, aRow, bRow, column, dir, sorterParams) => {
      const aValue = a && typeof a === 'object' && a.value ? a.value : a;
      const bValue = b && typeof b === 'object' && b.value ? b.value : b;
      return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
    }
  });
}

export default Tabulator;
