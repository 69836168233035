import { Controller } from "@hotwired/stimulus";
import {sendTurboStreamRequest} from "../helpers/turbo_stream_helper"
import {buildHeaders, buildRequest, buildUrl} from "../helpers/build_request"


// Connects to data-controller="warehouse-direct-transfer"
export default class extends Controller {
  static targets = [
    'quantity',
    'completeMissingStockButton',
    'stockCard'
  ];

  connect() {
    document.addEventListener('missingStocksRequestSended',() => this.disableMissingStockButton());
  }

  disableMissingStockButton() {
    this.completeMissingStockButtonTarget.disabled = true
  }

  dispatchCloseModalEvent() {
    const event = new CustomEvent('closeModal', { bubbles: true, cancelable: true });
    document.dispatchEvent(event);
  }

  // For Direct Transfer Lot Selection

  updateQuantityCheckedIn(event) {
    const quantityCheckedIn = document.getElementById(event.target.dataset.quantityCheckedInId);
    const totalQuantity = this.sumLotSelectionQuantitys(event);

    // Check if sumLotSelectionQuantitys returns null
    if (totalQuantity === null) {
      event.preventDefault();
      return $notification({ text: event.target.dataset.nonIntegerAlertMessage, variant: 'error' });
    }

    quantityCheckedIn.value = totalQuantity;
    this.dispatchCloseModalEvent();
  }

  sumLotSelectionQuantitys(event) {
    const thisEventsQuantities = this.quantityTargets.filter(target => target.id.includes(`stock_card_${event.target.dataset.stockCarId}_`))

    let sum = 0;

    for (const iterator of thisEventsQuantities) {
      if (iterator.value === "" || iterator.value === undefined) {
        continue; // Skip empty inputs
      }
      const numericValue = parseFloat(iterator.value);
      if (!Number.isInteger(numericValue)) {
        return null; // Return null if any non-integer value is found
      }
      sum += numericValue;
    }

    return sum;
  }

  updateTransferAmountWithoutLot(event) {
    const parentDiv = event.target.parentElement;
    const transferAmountWithoutLotInput = parentDiv.querySelector(`#${event.target.dataset.transferAmountWithoutLotId}`);
    transferAmountWithoutLotInput.value = event.target.value
  }

  // Direct Transfer Stock Card Turbo Stream Request
  sendTurboRequestForStockCard(event) {
    const selectBox = event.target;
    const turboParams = JSON.parse(selectBox.dataset.turboParams);

    const params = {id: selectBox.value, ...turboParams};
    delete params.url;
    if (params.id !== '' && params.id !== selectBox.dataset.lastSelectedStockCardId) {
      const url = buildUrl(turboParams.url, params);
      const request = buildRequest(url, buildHeaders());
      sendTurboStreamRequest(request);
    }
  }

  stockCardOnChange(event) {
    const selectBox = event.target;
    const valueExists = this.stockCardTargets
                            .filter(c => c != event.target)
                            .some(el => el.value === selectBox.value);
    if (valueExists) {
      selectBox.tomselect.setValue(selectBox.dataset.lastSelectedStockCardId);
      $notification({ text: selectBox.dataset.lastSelectedStockCardErrorMessage, variant: 'error' });
    }
  }

  // Missing Stock Turbo Stream Request
  completeMissingStock(event) {
    event.preventDefault();
    const button = event.currentTarget;
    const url = buildUrl(button.dataset.turboUrl, {});
    const request = buildRequest(url, buildHeaders());
    sendTurboStreamRequest(request, 'missingStocksRequestSended');
  }
}
